module.exports = [{
      plugin: require('../../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-source-wordpress-experimental/gatsby-browser.js'),
      options: {"plugins":[],"schema":{"perPage":10,"timeout":300000,"requestConcurrency":4,"previewRequestConcurrency":2,"queryDepth":15,"circularQueryLimit":5,"typePrefix":"Wp"},"url":"https://cs1testdev.wpengine.com/graphql","verbose":true,"develop":{"nodeUpdateInterval":3000,"hardCacheMediaFiles":true,"hardCacheData":false},"production":{"hardCacheMediaFiles":false},"debug":{"graphql":{"showQueryOnError":false,"showQueryVarsOnError":true,"copyQueryOnError":true,"panicOnError":true,"onlyReportCriticalErrors":true,"copyNodeSourcingQueryAndExit":false,"writeQueriesToDisk":false},"timeBuildSteps":false,"disableCompatibilityCheck":false,"throwRefetchErrors":false},"excludeFieldNames":["blocksJSON","saveContent"],"type":{"Post":{"limit":5000}}},
    },{
      plugin: require('../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Gatsby WooCommerce Theme","short_name":"Electra","start_url":"/","background_color":"#eaeaea","theme_color":"#1e1e1e","display":"standalone","icon":"/opt/buildhome/repo/packages/formulyst-cs/src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"c6155ffbcdea91e2d2c8d9afd212a48d"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../packages/formulyst-cs/gatsby-browser.js'),
      options: {"plugins":[],"wordPressUrl":"https://cs1testdev.wpengine.com","gatsbySiteUrl":"https://cs-formulyst-1.prashanthca.in","googleTagManagerId":"xyz","fbAppId":"abc"},
    }]
